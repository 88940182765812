<template>
  <v-row
    no-gutters
    class="mt-1"
  >
    <v-col>
      <div
        v-if="loadingPlugins"
        class="text-center mt-6"
      >
        <v-progress-circular
          class="mx-auto"
          indeterminate
        />
      </div>

      <v-card
        v-else
        outlined
        data-cy="plugins-card"
      >
        <v-card-text>
          <template v-if="!showAdditionalPluginsOnly">
            <div class="card-item bundle-title d-flex flex-column flex-sm-row align-sm-center pa-4">
              <div class="d-flex flex-row flex-grow-1 justify-space-between">
                <div>{{ availablePlugins.mainPlugin.name }}</div>
                <material-product-details-change-plan-plugins-switcher
                  :plugin="availablePlugins.mainPlugin"
                  :disabled="true"
                />
              </div>
              <div class="plugin-price-col">
                {{ availablePlugins.mainPlugin.price }}
              </div>
            </div>

            <template v-for="bundle in availablePlugins.bundles">
              <div
                :key="'b-' + bundle.easy_price_book_product_id"
                class="card-item bundle-title d-flex flex-column flex-sm-row align-sm-center pa-4"
              >
                <div class="d-flex flex-row flex-grow-1 justify-space-between">
                  <div>{{ bundle.name }}</div>
                  <material-product-details-change-plan-plugins-bundles-switcher
                    :bundle="bundle"
                    :selected-plugin-ids="selectedPluginIds"
                    @bundle-change="onBundleChange"
                  />
                </div>
                <div class="plugin-price-col">
                  {{ bundle.price }}
                </div>
              </div>

              <material-product-details-change-plan-plugins-table-row
                v-for="plugin in bundle.plugins"
                :key="`${bundle.easy_price_book_product_id}-${plugin.easy_price_book_product_id}`"
                :checked="isPluginChecked(plugin.easy_price_book_product_id)"
                :plugin="plugin"
                @plugin-change="onPluginChange"
              />
            </template>
          </template>

          <div
            v-if="Array.isArray(availablePlugins.additionalPlugins)
              && availablePlugins.additionalPlugins.length > 0"
            class="card-item bundle-title pa-4"
          >
            {{ $t('productDetails.additionalPlugins') }}
          </div>
          <material-product-details-change-plan-plugins-table-row
            v-for="plugin in availablePlugins.additionalPlugins"
            :key="'p-' + plugin.easy_price_book_product_id"
            :checked="isPluginChecked(plugin.easy_price_book_product_id)"
            :plugin="plugin"
            @plugin-change="onPluginChange"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    selectedPlugins: {
      type: Array,
      required: true
    },
    showAdditionalPluginsOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('productDetails', ['availablePlugins', 'loadingPlugins']),
    selectedPluginIds () {
      return (this.selectedPlugins || []).map(p => p.easy_price_book_product_id)
    }
  },
  methods: {
    isPluginChecked (pluginId) {
      return this.selectedPluginIds.includes(pluginId)
    },
    onPluginChange (data) {
      this.$emit('plugin-change', data)
    },
    onBundleChange (data) {
      this.$emit('bundle-change', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.plugin-price-col {
  width: 30%;
  text-align: right;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .plugin-price-col {
    width: 100%;
  }
}

.card-item {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }
}
</style>
